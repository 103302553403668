const keysPhysicalTemplate = ['businessName', 'email', 'nif', 'bankAccountNumber', 'address', 'phone']

const keysLegalTemplate = [...keysPhysicalTemplate, 'representativeNif', 'addressOfTheRepresentative']

const originalKeys = {
	businessName: 'Nombre',
	email: 'Email',
	nif: 'DNI',
	representativeNif: 'NIF de Representante',
	bankAccountNumber: 'IBAN',
	address: 'Dirección',
	addressOfTheRepresentative: 'Dirección de Representante',
	phone: 'Teléfono',
}

export const validationInfoTemplate = (info) => {
	let response = { success: false, missingValues: [] }
	const missingValues =
		info.personType === 'legal'
			? keysLegalTemplate.reduce((acc, key) => {
					if (!(key in info) || info[key] === null || info[key] === undefined || info[key] === '') {
						acc.push(key)
					}
					return acc
			  }, [])
			: keysPhysicalTemplate.reduce((acc, key) => {
					if (!(key in info) || info[key] === null || info[key] === undefined) {
						acc.push(key)
					}
					return acc
			  }, [])

	if (missingValues.length > 0) {
		response.success = false
		response.missingValues = missingValues.map((value) => originalKeys[value] || value)
		return response
	} else {
		response.success = true
		return response
	}
}
