const matchProjectStatus = (status, es) => {
	if (!es) {
		switch (status) {
			case 'startSoon':
				return 'Inicia pronto'
			case 'workInProgress':
				return 'Obras en Curso'
			case 'onSale':
				return 'En Venta'
			case 'reserved':
				return 'Reservado'
			case 'finished':
				return 'Finalizado'
			case 'liquidated':
				return 'Liquidado'
			case 'notPublished':
				return 'No Publicado'
			default:
				return status
		}
	} else {
		switch (status) {
			case 'Inicia pronto':
				return 'startSoon'
			case 'Obras en Curso':
				return 'workInProgress'
			case 'En Venta':
				return 'onSale'
			case 'Reservado':
				return 'reserved'
			case 'Finalizado':
				return 'finished'
			case 'Liquidado':
				return 'liquidated'
			case 'No Publicado':
				return 'notPublished'
			default:
				return status
		}
	}
}

const matchLiquidation = (status) => {
	switch (status) {
		case 'pay':
			return 'Recibidos'
		case 'reinvest':
			return 'Reinvertir'
		case 'benefits-only':
			return 'Solo beneficios recibidos'
		default:
			return ''
	}
}

const matchPersonType = (personType) => {
	switch (personType) {
		case 'legal':
			return 'Persona Jurídica'
		case 'physical':
			return 'Persona Física'
		case 'none':
			return 'Usuario'
		default:
			return ''
	}
}

const matchProfileType = (profileType) => {
	switch (profileType) {
		case 'prescriber':
			return 'Prescriptor'
		case 'shareholder':
			return 'Accionista'
		case 'none':
			return 'Sin Perfil'
		default:
			return ''
	}
}

const matchPersonToEnglish = (person) => {
	switch (person) {
		case 'Persona jurídica':
			return 'legal'
		case 'Persona física':
			return 'physical'
		default:
			return ''
	}
}

const matchStatusDecline = (declineStatus) => {
	switch (declineStatus) {
		case 'resolved':
			return 'Retiro Exitoso'
		case 'reject':
			return 'Contactanos'
		case 'pending':
			return 'Anulación Solicitada'
		case 'none':
			return 'none'
		default:
			return ''
	}
}

/**
 * @param {String} role
 * @returns {String} matched role in string
 */
const matchRoleName = (role) => {
	switch (role) {
		case 'superadministrator':
			return 'Super Admin'
		case 'administrator':
			return 'Admin'
		case 'editor':
			return 'Editor'
		case 'investor':
			return 'Inversor'
		default:
			return 'No Role'
	}
}

const matchStatusUser = (status, eng) => {
	if (!eng) {
		switch (status) {
			case 'active':
				return 'Activo'
			case 'inactive':
				return 'Inactivo'
		}
	}
	return status
}

const matchDate = (date, eng) => {
	if (date && eng) {
		return date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
	} else if (!date) {
		return ''
	}
	return new Date(date).toLocaleDateString()
}

const matchGender = (gender, eng) => {
	if (eng) {
		switch (gender) {
			case 'male':
				return 'Hombre'
			case 'female':
				return 'Mujer'
			default:
				return gender
		}
	} else {
		switch (gender) {
			case 'Hombre':
				return 'male'
			case 'Mujer':
				return 'female'
			default:
				return gender
		}
	}
}

const matchStatusInvestmentConfirmation = (confirmationRecived) => {
	switch (confirmationRecived) {
		case 'resolved':
			return 'Retiro Exitoso'
		case 'reject':
			return 'Rechazado'
		case 'pending':
			return 'Anulación Solicitada'
		case 'none':
			return 'none'
		default:
			return ''
	}
}

const matchLogConstants = (logConstant) => {
	switch (logConstant) {
		case 'INVESTMENT_ADMIN_DECLINE_FAIL':
			return 'Inversiones eliminadas'
		case 'INVESTMENT_ADMIN_DECLINE_REQUEST':
			return 'Inversiones eliminadas'
		case 'REINVESTMENT_REGISTER_SUCCESS':
			return 'Reinversiones'
		case 'REINVESTMENT_REGISTER_FAIL':
			return 'Reinversiones'
		case 'INVESTMENT_REGISTER_SUCCESS':
			return 'Inversiones'
		case 'INVESTMENT_REGISTER_FAIL':
			return 'Inversiones'
		case 'USER_LOGIN_SUCCESS':
			return 'Inicios de sesión'
		case 'USER_LOGIN_FAIL':
			return 'Inicios de sesión'
		case 'INCOME_REGISTER_SUCCESS':
			return 'Confirmación de ingresos'
		case 'INCOME_REGISTER_FAIL':
			return 'Confirmación de ingresos'
		case ' INCOME_DELETE_DATE_SUCCESS':
			return 'Fechas de Ingreso eliminadas'
		case 'SIGN_INVESTMENT_REGISTER_SUCCESS':
			return 'Contratos firmados'
		case 'SIGN_INVESTMENT_REGISTER_FAIL':
			return 'Contratos firmados'
		case 'INVESTMENT_LIQUIDATED_REGISTER_SUCCESS':
			return 'Inversiones liquidadas'
		case 'CONTRATC_ID_INVESTMENT_REGISTER_SUCCESS':
			return 'Inversiones ID contrato'
		case 'CONTRAT_FILE_INVESTMENT_REGISTER_SUCCESS':
			return 'Inversiones con contratos en servidor'
		case 'CONTRAT_FILE_INVESTMENT_REGISTER_OUT_CONTRACT_SUCCESS':
			return 'Inversiones sin contratos en servidor'
		case 'CONTRAT_FILE_INVESTMENT_REGISTER_FAIL':
			return 'Inversiones sin contratos'
		default:
			return ''
	}
}

const diacriticSensitiveRegex = (string = '') => {
	string
		.replace(/a/g, '[a,á,à,ä]')
		.replace(/e/g, '[e,é,ë]')
		.replace(/i/g, '[i,í,ï]')
		.replace(/o/g, '[o,ó,ö,ò]')
		.replace(/u/g, '[u,ü,ú,ù]')

	return string
}

module.exports = {
	matchProjectStatus,
	matchPersonType,
	matchProfileType,
	matchStatusDecline,
	matchRoleName,
	matchStatusUser,
	matchPersonToEnglish,
	matchDate,
	matchGender,
	matchStatusInvestmentConfirmation,
	matchLogConstants,
	matchLiquidation,
	diacriticSensitiveRegex,
}
