import { LEGAL, LEGAL_PERSON, PHYSICAL, PHYSICAL_PERSON } from './helper-variables'

export const getPersonTypeLabel = (personType) => {
	switch (personType) {
		case PHYSICAL:
			return PHYSICAL_PERSON
		case LEGAL:
			return LEGAL_PERSON
		default:
			return PHYSICAL_PERSON
	}
}
