const generateTokenCode = (token) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result1 = ''
  let result2 = ''
  const charactersLength = characters.length
  for (let i = 0; i < 3; i++) {
    result1 += characters.charAt(Math.floor(Math.random() * charactersLength))
    result2 += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result1.concat(token).concat(result2)
}

module.exports = {
  generateTokenCode,
}
