export function debounce(func, wait) {
	let timeout
	return function executedFunction(...args) {
		const later = () => {
			timeout = null
			func(...args)
		}
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
	}
}

/**
 * function to delay a request from input search
 * @param {number} seconds
 * @param {ref} ref
 * @param {callBack} onChange
 */
export const onChangeDelayTime = (ref, onChange, seconds = 0.5) => {
	clearTimeout(ref.current)
	ref.current = setTimeout(() => {
		if (onChange) {
			onChange()
		}
	}, seconds * 1000)
}
