/**
 * Parses a string representing a currency amount to a number.
 * Removes any non-digit and non-comma characters from the string.
 * Replaces any dots with commas.
 * Returns the parsed number as a float.
 *
 * @param {string} currencyString - The string to parse. Example: "20.086,67 €" or "13.366,65 €".
 * @return {number} The parsed number.
 */
export const parseFromStringToCurrencyNumber = (currencyString) => {
	if (!currencyString) return 0

	// Remove any non-digit and non-comma characters from the string.
	let cleanedString = currencyString.replace(/[^\d,-]/g, '')

	// Replace any dots with commas.
	cleanedString = cleanedString.replace(/\./g, '')
	cleanedString = cleanedString.replace(/,/g, '.')

	// Parse the cleaned string as a float.
	const parsedNumber = parseFloat(cleanedString)

	return parsedNumber
}

export function formatCurrency(number) {
	const formatter = new Intl.NumberFormat('es-ES', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})

	return formatter.format(number)
}
