/**
 * Limits a text to a maximum length and adds ellipsis if necessary to not exceed the limit.
 * @param {string} text - The original text to be truncated.
 * @param {number} maxLength - The maximum allowed length for the text.
 * @returns {string} The truncated text, with ellipsis added if necessary.
 */
export const limitText = (text, maxLength) => {
	if (text.length <= maxLength) {
		return text
	} else {
		const lastSpaceIndex = text.lastIndexOf(' ', maxLength)
		return text.substring(0, lastSpaceIndex) + '...'
	}
}
