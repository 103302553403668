function fv(rate, nper, pmt, pv) {
  rate = parseFloat(rate)
  nper = parseFloat(nper)
  pmt = parseFloat(pmt)
  pv = parseFloat(pv)

  if (nper == 0) {
    return 0
  }
  if (rate == 0) {
    fv_value = -(pv + pmt * nper)
  } else {
    x = Math.pow(1 + rate, nper)
    fv_value = -(-pmt + x * pmt + rate * x * pv) / rate
  }

  fv_value = fv_value.toFixed(2)

  return parseFloat(fv_value)
}

function futureValue(rate, nper, pmt, pv) {
  rate = parseFloat(rate)
  nper = parseFloat(nper)
  pmt = parseFloat(pmt)
  pv = parseFloat(pv)

  const pow = Math.pow(1 + rate, nper)

  let fv = 0
  if (rate) {
    fv = (pmt * (1 + rate) * (1 - pow)) / rate - pv * pow
  } else {
    fv = -1 * (pv + pmt * nper)
  }
  return fv.toFixed(2)
}

module.exports = {
  fv,
  futureValue,
}
