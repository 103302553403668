'use strict'

/**
 * @param {Object} modalInfo modal info
 * @param {Function} setInfo set modal info
 * @param {Function} setModal set open modal
 * @return open modal with info
 */
export const openModal = (modalInfo, setInfo, setModal) => {
  setInfo(modalInfo)
  setModal(true)
}

/**
 * @param {Function} setInfo set modal info
 * @param {Function} setModal set close modal
 * @return close modal and clean info
 */
export const closeModal = (setInfo, setModal) => {
  setModal(false)
  setInfo({})
}
