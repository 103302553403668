export const countriesList = [
	{ name: 'argentina', nationality: 'argentina', code: 'ARG' },
	{ name: 'españa', nationality: 'española', code: 'ESP' },
	{ name: 'méxico', nationality: 'mexicana', code: 'MEX' },
	{ name: 'brasil', nationality: 'brasileña', code: 'BRA' },
	{ name: 'estados unidos', nationality: 'estadounidense', code: 'USA' },
	{ name: 'canadá', nationality: 'canadiense', code: 'CAN' },
	{ name: 'francia', nationality: 'francesa', code: 'FRA' },
	{ name: 'italia', nationality: 'italiana', code: 'ITA' },
	{ name: 'alemania', nationality: 'alemana', code: 'DEU' },
	{ name: 'reino unido', nationality: 'británica', code: 'GBR' },
	{ name: 'japón', nationality: 'japonesa', code: 'JPN' },
	{ name: 'china', nationality: 'china', code: 'CHN' },
	{ name: 'india', nationality: 'india', code: 'IND' },
	{ name: 'rusia', nationality: 'rusa', code: 'RUS' },
	{ name: 'australia', nationality: 'australiana', code: 'AUS' },
	{ name: 'sudáfrica', nationality: 'sudafricana', code: 'ZAF' },
	{ name: 'corea del sur', nationality: 'surcoreana', code: 'KOR' },
	{ name: 'argelia', nationality: 'argelina', code: 'DZA' },
	{ name: 'egipto', nationality: 'egipcia', code: 'EGY' },
	{ name: 'marruecos', nationality: 'marroquí', code: 'MAR' },
	{ name: 'nigeria', nationality: 'nigeriana', code: 'NGA' },
	{ name: 'cuba', nationality: 'cubana', code: 'CUB' },
	{ name: 'haití', nationality: 'haitiana', code: 'HTI' },
	{ name: 'perú', nationality: 'peruana', code: 'PER' },
	{ name: 'uruguay', nationality: 'uruguaya', code: 'URY' },
	{ name: 'venezuela', nationality: 'venezolana', code: 'VEN' },
	{ name: 'filipinas', nationality: 'filipina', code: 'PHL' },
	{ name: 'nepal', nationality: 'nepalí', code: 'NPL' },
	{ name: 'pakistán', nationality: 'paquistaní', code: 'PAK' },
	{ name: 'chile', nationality: 'chilena', code: 'CHL' },
	{ name: 'indonesia', nationality: 'indonesia', code: 'IDN' },
	{ name: 'tailandia', nationality: 'tailandesa', code: 'THA' },
	{ name: 'vietnam', nationality: 'vietnamita', code: 'VNM' },
	{ name: 'qatar', nationality: 'qatarí', code: 'QAT' },
	{ name: 'kuwait', nationality: 'kuwaití', code: 'KWT' },
	{ name: 'madagascar', nationality: 'malgache', code: 'MDG' },
	{ name: 'mauricio', nationality: 'mauriciana', code: 'MUS' },
	{ name: 'zambia', nationality: 'zambiana', code: 'ZMB' },
	{ name: 'kenia', nationality: 'keniata', code: 'KEN' },
	{ name: 'angola', nationality: 'angoleña', code: 'AGO' },
	{ name: 'camerún', nationality: 'camerunesa', code: 'CMR' },
	{ name: 'etiopía', nationality: 'etíope', code: 'ETH' },
	{ name: 'ghana', nationality: 'ghanesa', code: 'GHA' },
	{ name: 'mozambique', nationality: 'mozambiqueña', code: 'MOZ' },
	{ name: 'turquía', nationality: 'turca', code: 'TUR' },
	{ name: 'iran', nationality: 'iraní', code: 'IRN' },
	{ name: 'israel', nationality: 'israelí', code: 'ISR' },
	{ name: 'emiratos árabes unidos', nationality: 'emiratí', code: 'ARE' },
	{ name: 'arabia saudita', nationality: 'saudí', code: 'SAU' },
	{ name: 'irak', nationality: 'iraquí', code: 'IRQ' },
	{ name: 'jordania', nationality: 'jordana', code: 'JOR' },
	{ name: 'libia', nationality: 'libia', code: 'LBY' },
	{ name: 'países bajos', nationality: 'holandés', code: 'NLD' },
	{ name: 'suecia', nationality: 'sueca', code: 'SWE' },
	{ name: 'zimbabue', nationality: 'zimbabuense', code: 'ZWE' },
	{ name: 'nueva zelanda', nationality: 'neozelandesa', code: 'NZL' },
	{ name: 'paraguay', nationality: 'paraguaya', code: 'PRY' },
	{ name: 'bolivia', nationality: 'boliviana', code: 'BOL' },
	{ name: 'colombia', nationality: 'colombiana', code: 'COL' },
	{ name: 'panamá', nationality: 'panameña', code: 'PAN' },
	{ name: 'portugal', nationality: 'portuguesa', code: 'PRT' },
	{ name: 'ucrania', nationality: 'ucraniana', code: 'UKR' },
	{ name: 'ecuador', nationality: 'ecuatoriana', code: 'ECU' },
	{ name: 'guatemala', nationality: 'guatemalteca', code: 'GTM' },
	{ name: 'rumania', nationality: 'rumana', code: 'ROU' },
	{ name: 'croacia', nationality: 'croata', code: 'HRV' },
	{ name: 'afganistán', nationality: 'afgana', code: 'AFG' },
	{ name: 'albania', nationality: 'albanesa', code: 'ALB' },
	{ name: 'andorra', nationality: 'andorrana', code: 'AND' },
	{ name: 'anguila', nationality: 'anguilana', code: 'AIA' },
	{ name: 'antigua y barbuda', nationality: 'antiguan', code: 'ATG' },
	{ name: 'armenia', nationality: 'armenia', code: 'ARM' },
	{ name: 'austria', nationality: 'austriaca', code: 'AUT' },
	{ name: 'azerbaiyán', nationality: 'azerbaiyana', code: 'AZE' },
	{ name: 'bahamas', nationality: 'bahameña', code: 'BHS' },
	{ name: 'barbados', nationality: 'barbadense', code: 'BRB' },
	{ name: 'bélgica', nationality: 'belga', code: 'BEL' },
	{ name: 'belice', nationality: 'beliceña', code: 'BLZ' },
	{ name: 'benín', nationality: 'beninesa', code: 'BEN' },
	{ name: 'bhután', nationality: 'butanesa', code: 'BTN' },
	{ name: 'bielorrusia', nationality: 'bielorrusa', code: 'BLR' },
	{ name: 'bosnia y herzegovina', nationality: 'bosnia', code: 'BIH' },
	{ name: 'botsuana', nationality: 'botsuanesa', code: 'BWA' },
	{ name: 'brunéi', nationality: 'bruneana', code: 'BRN' },
	{ name: 'bulgaria', nationality: 'búlgara', code: 'BGR' },
	{ name: 'burkina faso', nationality: 'burkinesa', code: 'BFA' },
	{ name: 'burundi', nationality: 'burundesa', code: 'BDI' },
	{ name: 'cabo verde', nationality: 'caboverdiana', code: 'CPV' },
	{ name: 'camboya', nationality: 'camboyana', code: 'KHM' },
	{ name: 'catar', nationality: 'qatarí', code: 'QAT' },
	{ name: 'chad', nationality: 'chadiana', code: 'TCD' },
	{ name: 'chequia', nationality: 'checa', code: 'CZE' },
	{ name: 'chipre', nationality: 'chipriota', code: 'CYP' },
	{ name: 'comoras', nationality: 'comorense', code: 'COM' },
	{ name: 'república del congo', nationality: 'congoleña', code: 'COG' },
	{
		name: 'república democrática del congo',
		nationality: 'congoleña',
		code: 'COD',
	},
	{ name: 'corea del norte', nationality: 'norcoreana', code: 'PRK' },
	{ name: 'costa de marfil', nationality: 'marfileña', code: 'CIV' },
	{ name: 'costa rica', nationality: 'costarricense', code: 'CRI' },
	{ name: 'dinamarca', nationality: 'danesa', code: 'DNK' },
	{ name: 'dominica', nationality: 'dominiquesa', code: 'DMA' },
	{
		name: 'república dominicana',
		nationality: 'dominicana',
		code: 'DOM',
	},
	{ name: 'el salvador', nationality: 'salvadoreña', code: 'SLV' },
	{ name: 'eslovaquia', nationality: 'eslovaca', code: 'SVK' },
	{ name: 'eslovenia', nationality: 'eslovena', code: 'SVN' },
	{ name: 'micronesia', nationality: 'micronesia', code: 'FSM' },
	{ name: 'fiyi', nationality: 'fiyiana', code: 'FJI' },
	{ name: 'finlandia', nationality: 'finlandesa', code: 'FIN' },
	{ name: 'gabón', nationality: 'gabonesa', code: 'GAB' },
	{ name: 'gambia', nationality: 'gambiana', code: 'GMB' },
	{ name: 'georgia', nationality: 'georgiana', code: 'GEO' },
	{ name: 'granada', nationality: 'granadina', code: 'GRD' },
	{ name: 'grecia', nationality: 'griega', code: 'GRC' },
	{ name: 'guinea', nationality: 'guineana', code: 'GIN' },
	{ name: 'guinea-bisáu', nationality: 'guineana', code: 'GNB' },
	{
		name: 'guinea ecuatorial',
		nationality: 'ecuatoguineana',
		code: 'GNQ',
	},
	{ name: 'guyana', nationality: 'guyanesa', code: 'GUY' },
	{ name: 'honduras', nationality: 'hondureña', code: 'HND' },
	{ name: 'hungría', nationality: 'húngara', code: 'HUN' },
	{ name: 'islandia', nationality: 'islandesa', code: 'ISL' },
	{ name: 'irán', nationality: 'iraní', code: 'IRN' },
	{ name: 'irlanda', nationality: 'irlandesa', code: 'IRL' },
	{ name: 'jamaica', nationality: 'jamaicana', code: 'JAM' },
	{ name: 'kazajistán', nationality: 'kazaja', code: 'KAZ' },
	{ name: 'kirguistán', nationality: 'kirguís', code: 'KGZ' },
	{ name: 'kiribati', nationality: 'kiribatiana', code: 'KIR' },
	{ name: 'laos', nationality: 'laosiana', code: 'LAO' },
	{ name: 'letonia', nationality: 'letona', code: 'LVA' },
	{ name: 'líbano', nationality: 'libanesa', code: 'LBN' },
	{ name: 'lesoto', nationality: 'lesotense', code: 'LSO' },
	{ name: 'liberia', nationality: 'liberia', code: 'LBR' },
	{
		name: 'liechtenstein',
		nationality: 'liechtensteiniana',
		code: 'LIE',
	},
	{ name: 'lituania', nationality: 'lituana', code: 'LTU' },
	{ name: 'luxemburgo', nationality: 'luxemburguesa', code: 'LUX' },
	{ name: 'macedonia', nationality: 'macedonia', code: 'MKD' },
	{ name: 'malasia', nationality: 'malasia', code: 'MYS' },
	{ name: 'malaui', nationality: 'malauí', code: 'MWI' },
	{ name: 'maldivas', nationality: 'maldiva', code: 'MDV' },
	{ name: 'malí', nationality: 'maliense', code: 'MLI' },
	{ name: 'malta', nationality: 'maltesa', code: 'MLT' },
	{ name: 'mauritania', nationality: 'mauritana', code: 'MRT' },
	{ name: 'moldavia', nationality: 'moldava', code: 'MDA' },
	{ name: 'mónaco', nationality: 'monegasca', code: 'MCO' },
	{ name: 'mongolia', nationality: 'mongola', code: 'MNG' },
	{ name: 'montenegro', nationality: 'montenegrina', code: 'MNE' },
	{ name: 'birmania', nationality: 'birmana', code: 'MMR' },
	{ name: 'namibia', nationality: 'namibia', code: 'NAM' },
	{ name: 'nauru', nationality: 'nauruana', code: 'NRU' },
	{ name: 'nicaragua', nationality: 'nicaragüense', code: 'NIC' },
	{ name: 'niger', nationality: 'nigerina', code: 'NER' },
	{ name: 'noruega', nationality: 'noruega', code: 'NOR' },
	{ name: 'omán', nationality: 'omaní', code: 'OMN' },
	{ name: 'palau', nationality: 'palauana', code: 'PLW' },
	{ name: 'palestina', nationality: 'palestina', code: 'PSE' },
	{ name: 'papúa nueva guinea', nationality: 'papuana', code: 'PNG' },
	{ name: 'polonia', nationality: 'polaca', code: 'POL' },
	{ name: 'ruanda', nationality: 'ruandesa', code: 'RWA' },
	{ name: 'rumanía', nationality: 'rumana', code: 'ROU' },
	{ name: 'samoa', nationality: 'samoana', code: 'WSM' },
	{
		name: 'san cristóbal y nieves',
		nationality: 'sancristobaleña',
		code: 'KNA',
	},
	{ name: 'san marino', nationality: 'sanmarinense', code: 'SMR' },
	{
		name: 'san vicente y las granadinas',
		nationality: 'vicentina',
		code: 'VCT',
	},
	{ name: 'santa lucía', nationality: 'santaluciana', code: 'LCA' },
	{
		name: 'santo tomé y príncipe',
		nationality: 'saotomense',
		code: 'STP',
	},
	{ name: 'senegal', nationality: 'senegalesa', code: 'SEN' },
	{ name: 'serbia', nationality: 'serbia', code: 'SRB' },
	{ name: 'seychelles', nationality: 'seychelense', code: 'SYC' },
	{ name: 'sierra leona', nationality: 'sierraleonesa', code: 'SLE' },
	{ name: 'singapur', nationality: 'singapurense', code: 'SGP' },
	{ name: 'sirio', nationality: 'sirio', code: 'SYR' },
	{ name: 'somalia', nationality: 'somalí', code: 'SOM' },
	{ name: 'sri lanka', nationality: 'ceilandesa', code: 'LKA' },
	{ name: 'suazilandia', nationality: 'suazi', code: 'SWZ' },
	{ name: 'sudán', nationality: 'sudanesa', code: 'SDN' },
	{ name: 'sudán del sur', nationality: 'sur sudanesa', code: 'SSD' },
	{ name: 'suiza', nationality: 'suiza', code: 'CHE' },
	{ name: 'surinam', nationality: 'surinamesa', code: 'SUR' },
	{ name: 'tanzania', nationality: 'tanzana', code: 'TZA' },
	{ name: 'tayikistán', nationality: 'tayika', code: 'TJK' },
	{ name: 'timor oriental', nationality: 'timorense', code: 'TLS' },
	{ name: 'togo', nationality: 'togolesa', code: 'TGO' },
	{ name: 'tonga', nationality: 'tongana', code: 'TON' },
	{ name: 'trinidad y tobago', nationality: 'trinitense', code: 'TTO' },
	{ name: 'túnez', nationality: 'tunecina', code: 'TUN' },
	{ name: 'turkmenistán', nationality: 'turcomana', code: 'TKM' },
	{ name: 'tuvalu', nationality: 'tuvaluana', code: 'TUV' },
	{ name: 'uganda', nationality: 'ugandesa', code: 'UGA' },
	{ name: 'uzbekistán', nationality: 'uzbeka', code: 'UZB' },
	{ name: 'vanuatu', nationality: 'vanuatuense', code: 'VUT' },
	{ name: 'yemen', nationality: 'yemení', code: 'YEM' },
	{ name: 'yibuti', nationality: 'yibutiana', code: 'DJI' },
].sort((a, b) => {
	const nombreA = a.name.toLowerCase()
	const nombreB = b.name.toLowerCase()

	if (nombreA < nombreB) {
		return -1
	}
	if (nombreA > nombreB) {
		return 1
	}
	return 0
})
