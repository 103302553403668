const matchCurrencyToDecimal = (currencyAmount) => {
	const num = currencyAmount?.replace(/[^0-9\,-]+/g, '')
	if (currencyAmount?.includes('€')) {
		return parseFloat(num.replace(',', '.')).toFixed(2)
	} else {
		return currencyAmount
	}
}

const matchNumberToCurrency = (number) => {
	const defaultConfig = {
		style: 'currency',
		currency: 'EUR',
	}
	const defaultLenguajeCode = 'de-DE'
	const numberFormat = new Intl.NumberFormat(defaultLenguajeCode, defaultConfig)
	return numberFormat.format(number)
}

const replaceAll = (text, search, replace) => {
	while (text.toString().indexOf(search) != -1) text = text.toString().replace(search, replace)
	return text
}

const replaceStringToNum = (numInString) => {
	if (typeof numInString === 'string') {
		const replaceAll = (text, search, replace) => {
			while (text.toString().indexOf(search) != -1) text = text?.toString()?.replace(search, replace)
			return text
		}
		return Number(replaceAll(replaceAll(numInString?.replace('€', ''), '.', ''), ',', '.'))
	}
	return false
}

const numberToEURCurrency = (number) => {
	return number.replace('.', ',').toString() + ' €'
}

const parseNumberToEURCurrencyString = (number) => {
	return number.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
}

const onlyDecimalsWithComma = (amount) => {
	return amount.replace('€', '').replace('.', '')
}

export {
	matchCurrencyToDecimal,
	matchNumberToCurrency,
	numberToEURCurrency,
	onlyDecimalsWithComma,
	parseNumberToEURCurrencyString,
	replaceAll,
	replaceStringToNum,
}
