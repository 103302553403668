import { format } from 'date-fns'

export const profileNotesMapper = (notes) =>
	notes.map((n) => ({
		id: n?._id,
		noteBody: n?.body,
		userSender: n?.sender,
		hour: format(new Date(n.createdAt), 'hh:mm aaaa'),
		fullTimestamp: format(new Date(n.createdAt), 'dd/MM/yyyy - hh:mm aaaa'),
	}))
