import { orderByAmount, orderByString, orderByDate } from '../functionOrder/functionsOrder'
import { format } from 'date-fns'
import { Delete } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import { Tooltip } from '@material-ui/core'
import { openModal } from './modal-helper'

export const LIQUIDATEDINVESTMENT_COLUMNS = [
	{
		Header: 'Perfil Inversor',
		accessor: 'investorProfile',
		sortType: (paramA, paramB) => orderByString(paramA, paramB, 'investorProfile'),
		size: 'big',
	},
	{
		Header: 'Dirección de Proyecto',
		accessor: 'projectAddress',
		sortType: (paramA, paramB) => orderByString(paramA, paramB, 'projectAddress'),
		size: 'big',
	},
	{
		Header: 'Importe',
		accessor: 'amount',
		sortType: (paramA, paramB) => orderByAmount(paramA, paramB),
		size: 'small',
	},
	{
		Header: 'Retención',
		accessor: 'retention',
		sortType: (paramA, paramB) => orderByAmount(paramA, paramB),
		size: 'small',
	},
	{
		Header: 'Beneficio',
		accessor: 'benefit',
		sortType: (paramA, paramB) => orderByAmount(paramA, paramB),
		size: 'small',
	},
	{
		Header: 'A Pagar',
		accessor: 'toPay',
		sortType: (paramA, paramB) => orderByAmount(paramA, paramB),
		size: 'small',
	},
	{
		Header: 'Confirmación Ingreso',
		accessor: 'incomeRecivedDate',
		sortType: (paramA, paramB) => orderByAmount(paramA, paramB),
		size: 'big',
	},
	{
		Header: 'Fecha Inicio Proyecto',
		accessor: 'startDateUpdated',
		size: 'big',
	},
	{
		Header: 'Fecha Liquidación',
		accessor: 'dateLiquidation',
		size: 'big',
	},
	{
		Header: 'TIR Inversor',
		accessor: 'investorTIR',
		size: 'medium',
	},
	{
		Header: 'Fondos',
		accessor: 'liquidation',
		size: 'big',
	},
	{
		Header: 'Acciones',
		accessor: 'actions',
		size: 'small',
	},
]
export const INVESTMENTADMINLIST_COLUMNS = (
	showDeleteInvestmentDateHandler,
	showIncomeUpdateHandler,
	setIdInvestment,
	userInfo,
) => {
	return [
		{
			Header: 'Perfil Inversor',
			accessor: 'businessName',
			Cell: ({ cell }) => {
				return (
					<div
						onClick={() =>
							window.open(`/admin/investor-list-profiles?profileId=${cell.row.original.investorProfileId}`)
						}
						style={{ cursor: 'pointer', color: '#4646c3' }}
					>
						{cell.row.original.businessName}
					</div>
				)
			},
			size: 'small',
		},
		{
			Header: 'Email',
			accessor: 'email',
			size: 'small',
			hidden: true,
		},
		{
			Header: 'Dirección de Proyecto',
			accessor: 'projectAddress',
			sortType: (paramA, paramB) => orderByString(paramA, paramB, 'projectAddress'),
			size: 'big',
		},
		{
			Header: 'Fecha',
			accessor: 'investmentDate',
			Cell: ({ cell: { value } }) => <>{format(new Date(value), 'dd/MM/yyyy')}</>,
			sortType: (paramA, paramB) => orderByDate(paramA.values.investmentDate, paramB.values.investmentDate),
			size: 'small',
		},
		{
			Header: 'Importe',
			accessor: 'amount',
			sortType: (paramA, paramB) => orderByAmount(paramA, paramB),
			size: 'small',
		},
		{
			Header: 'Ingreso Recibido',
			accessor: 'incomeRecived',
			Cell: ({ cell }) => {
				let isAuthorizedUser = userInfo.isSupport || userInfo.isContabilidad
				return (
					<>
						{cell.value !== 'Confirmar ingreso' ? (
							<>
								{cell.value}{' '}
								{isAuthorizedUser && (
									<Tooltip title='Eliminar Ingreso Recibido' placement='bottom'>
										<Button
											justIcon
											round
											simple
											onClick={() =>
												showDeleteInvestmentDateHandler({
													...cell.row.original,
													_id: cell.row.original.id,
												})
											}
											color='danger'
											className='delete-date'
										>
											<Delete />
										</Button>
									</Tooltip>
								)}
							</>
						) : (
							<p
								style={{ cursor: 'pointer', color: '#4646c3' }}
								onClick={() => [
									showIncomeUpdateHandler({ ...cell.row.original, _id: cell.row.original.id }),
									setIdInvestment(cell.row.original.id),
								]}
							>
								{cell.value}
							</p>
						)}
					</>
				)
			},
			sortType: (paramA, paramB) => {
				return (
					paramA.values.incomeRecived &&
					paramB.values.incomeRecived &&
					orderByDate(paramA.values.investmentDate, paramB.values.investmentDate)
				)
			},
			size: 'medium',
		},
		{
			Header: 'Estado de Contrato',
			accessor: 'singedContract',
			size: 'medium',
		},
		{
			Header: 'A fin de Proyecto',
			accessor: 'liquidation',
			size: 'medium',
		},
		{
			Header: 'Estado',
			accessor: 'status',
			size: 'small',
		},
		{
			Header: 'Gestionado por',
			accessor: 'managedBy',
			size: 'medium',
		},
		{
			Header: 'Acciones',
			accessor: 'actions',
			size: 'small',
		},
	]
}

export const DELAYEDPROJECTS_COULUMNS = (setDelayedModal, setDelayedModalInfo) => {
	return [
		{
			Header: 'Dirección del proyecto',
			accessor: 'projectAddress',
			size: 'big',
		},
		{
			Header: 'Fecha de Fin',
			accessor: 'finishedDate',
			size: 'medium',
			Cell: ({ cell }) => {
				return (
					<div
						onClick={() => openModal(cell.row.original, setDelayedModalInfo, setDelayedModal)}
						style={{ cursor: 'pointer', color: '#4646c3' }}
					>
						{cell.row.original.finishedDate}
					</div>
				)
			},
		},
		{
			Header: 'Fecha de fin según dossier',
			accessor: 'finishDateDossier',
			size: 'big',
		},
		{
			Header: 'Días de retraso',
			accessor: 'delayedDays',
			size: 'medium',
		},
		{
			Header: 'Acciones',
			accessor: 'actions',
			size: 'small',
		},
	]
}

export const REFERAL_LIST_BY_PRESCRIBER = [
	{
		Header: 'Perfil',
		accessor: 'investorProfile',
		size: 'big',
	},
	{
		Header: 'Proyecto',
		accessor: 'projectAddress',
		size: 'big',
	},
	{
		Header: 'Importe de la Inversión',
		accessor: 'amount',
		size: 'small',
	},
	{
		Header: 'Fecha de Inversión',
		accessor: 'incomeRecivedDate',
		size: 'smmediumall',
	},
	{
		Header: 'Fecha Liquidación',
		accessor: 'liquidatedDate',
		size: 'smmediumall',
	},
	{
		Header: 'Inicio',
		accessor: 'startDate',
		size: 'small',
	},
	{
		Header: 'Fin',
		accessor: 'endDate',
		size: 'small',
	},
	{
		Header: 'Días',
		accessor: 'days',
		size: 'small',
	},
	{
		Header: 'Honorarios',
		accessor: 'bonus',
		size: 'medium',
	},
	{
		//FOR EXCEL
		Header: 'Total importe de Inversión',
		accessor: 'totalImport',
		size: 'medium',
		hidden: true,
	},
	{
		//FOR EXCEL
		Header: 'Total Honorarios',
		accessor: 'totalBonus',
		size: 'medium',
		hidden: true,
	},
	// {
	//   Header: 'Prescripción liquidada',
	//   accessor: 'prescriptionLiquidatedDate',
	//   size: 'big',
	// },
	{
		Header: 'Acciones',
		accessor: 'actions',
		size: 'small',
	},
]

export const SEEINFO_COLUMNS = [
	{
		Header: 'Usuario',
		accessor: 'user',
		size: 'small',
	},
	{
		Header: 'Fecha',
		accessor: 'createdAt',
		Cell: ({ cell: { value } }) => <>{format(new Date(value), 'dd/MM/yyyy')}</>,
		size: 'small',
	},
	{
		Header: 'Proyecto',
		accessor: 'projectName',
		size: 'small',
	},
]
