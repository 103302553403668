const investorUserInputsInitialState = {
	name: '',
	lastName: '',
	dni: '',
	phone: '',
	phone2: '',
	email: '',
	address: '',
	province: 'Madrid',
	location: 'Madrid',
	postalCode: '28001',
	country: '',
	retention: '19%',
	residenceCertificate: '',
	fiscalNationality: '',
	liquidation: 'pay',
	bankAccountNumber: '',
	gender: '',
	prescriber: '',
	businessName: '',
	cif: '',
	nif: '',
	businessPhone: '',
	businessEmail: '',
	businessAddress: '',
	representativeName: '',
	representativeLastName: '',
	representativeNif: '',
	representativeAddress: '',
	sponsor: '',
	personType: '',
	nationality: '',
	nationalityOfTheRepresentative: '',
}

export { investorUserInputsInitialState }
