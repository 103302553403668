import { generateTokenCode } from './generateTokenCode'

// KEEP THIS OBJECT UPDATED WITH THE LATEST KNOWN CONTAINERS
const STORAGE_CONTAINERS = [
	'projects-images',
	'projects-documents',
	'visits-images',
	'visits-documents',
	'visits-videos',
	'users-documents',
	'sh-users-documents',
	'sh-projects-images',
	'sh-projects-videos',
	'sh-projects-documents',
	'architecture-visits-documents',
	'architecture-visits-images',
	'architecture-visits-videos',
	'boe-container-documents',
	'boe-container-images',
	'boe-container-videos',
	'colaborators-documents',
	'colaborators-images',
	'colaborators-videos',
	'library-idealist-documents',
	'library-registrars-documents',
	'preprojects-documents',
	'preprojects-images',
	'preprojects-videos',
	'projects-videos',
	'supplier-invoice',
	'templates-documents',
	'zone-mapping-images',
]

// const STORAGE_CONTAINERS = Object.values(STORAGE_CONTAINERS_KEYS)

export const getUrlToOpenFile = (url, user) => {
	let openUrl
	if (url?.length > 0) {
		const dataUrl = url.split('/')
		if (dataUrl[0].startsWith('public')) {
			openUrl = `${process.env.REACT_APP_API}/${url}?code=${generateTokenCode(user?.token)}`
		} else if (STORAGE_CONTAINERS.includes(dataUrl[1])) {
			openUrl = `${process.env.REACT_APP_STORAGE_ACCOUNT_URL}${url}?${user?.mediaToken}`
		} else {
			openUrl = url
		}
	}
	return openUrl
}
