/**
 * function to delay a request from input search
 * @param {number} seconds
 * @param {ref} ref
 * @param {callBack} onChange
 */
export const onChangeDelayTime = (ref, onChange, seconds = 0.5) => {
	clearTimeout(ref.current)
	ref.current = setTimeout(() => {
		if (onChange) {
			onChange()
		}
	}, seconds * 1000)
}
