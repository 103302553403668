export const axiosResponseFunctionMiddleware = (error) => {
	const { response } = error
	const logoutStatusCode = 401
	const shouldLogout = response && logoutStatusCode === response.status

	if (shouldLogout) {
		localStorage.removeItem('setCHGG')
	}

	return Promise.reject(error)
}
